import React from "react";
import Layout from "../layout";
import styled from "styled-components";
import SEO from "../components/seo";
import {
  navyBlue,
  mainRed,
  aku,
  innerWidth,
  transHover,
  gotham,
  gothamBold,
  screen,
} from "../components/common/variables";
import Arrow from "../images/svg/right-arrow.svg";

const Wrapper = styled.div`
  padding-top: 124px;
  padding-bottom: 120px;
  max-width: ${innerWidth};
  margin: 0 auto;
  padding-left: 25px;
  padding-right: 25px;
  @media ${screen.small} {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 135px;
  }
  @media ${screen.medium} {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 150px;
  }
  @media ${screen.large} {
    padding-top: 200px;
  }
  @media ${screen.xLarge} {
    padding-left: 0;
    padding-right: 0;
  }

  .heading {
    color: ${navyBlue};
    font-family: ${aku};
    font-size: 2.1rem;
    @media ${screen.small} {
      font-size: 2.7rem;
    }

    svg {
      width: 14px;
      margin-right: 8px;
      vertical-align: middle;
      @media ${screen.small} {
        width: 19px;
        margin-right: 14px;
      }
      @media ${screen.medium} {
        width: 24px;
      }

      .body {
        fill: ${mainRed};
        transition: ${transHover};
      }
    }
  }

  .content {
    max-width: 1000px;

    &__heading {
      color: ${navyBlue};
      font-family: ${gothamBold};
      font-size: 1.1rem;
      margin-top: 20px;
      @media ${screen.small} {
        font-size: 1.2rem;
        margin-top: 30px;
      }
    }

    &__description {
      color: ${navyBlue};
      font-family: ${gotham};
      font-size: 1rem;
      margin-top: 7px;
      margin-left: 10px;
      margin-bottom: 25px;
      display: flex;
      align-items: flex-start;
      @media ${screen.small} {
        font-size: 1.1rem;
        margin-left: 35px;
        margin-top: 10px;
      }

      .arrow {
        display: block;

        svg {
          width: 8px;
          margin-right: 10px;
          margin-bottom: 1px;
          @media ${screen.small} {
            width: 10px;
            margin-right: 18px;
            margin-bottom: 0;
          }

          .body {
            fill: ${navyBlue};
          }
        }
      }
    }
  }
`;

const PrivacyPage = () => {
  return (
    <Layout>
      <SEO title="Disclaimer, Copyright & Privacy | TEN ALPHAS" />
      <Wrapper>
        <h2 className="heading">
          <Arrow />
          Disclaimer, Copyright & Privacy
        </h2>
        <main className="content">
          <h5 className="content__heading">Important</h5>
          <p className="content__description">
            <span className="arrow">
              <Arrow />
            </span>
            Your use of this site is subject to the terms set out below.
          </p>
          <h5 className="content__heading">Copyright</h5>
          <p className="content__description">
            <span className="arrow">
              <Arrow />
            </span>
            This Website and its contents are the property of TEN ALPHAS and are
            subject to copyright under Australian law and through international
            treaties, other countries. No use of the Website or contents is
            permitted without TEN ALPHAS’ authorisation.
          </p>
          <h5 className="content__heading">Jurisdiction</h5>
          <p className="content__description">
            <span className="arrow">
              <Arrow />
            </span>
            Use of this site and the provision of services by TEN ALPHAS are
            governed by and subject to Australian law. It is a condition of your
            use of this site that you submit to this jurisdiction.
          </p>
          <h5 className="content__heading">Disclaimer</h5>
          <p className="content__description">
            <span className="arrow">
              <Arrow />
            </span>
            The information contained within this Website and on any TEN ALPHAS
            products and services is of a general nature only. Changes may occur
            in circumstances at any time which may affect the accuracy or
            completeness of the information so while TEN ALPHAS have taken all
            reasonable care in producing this Website, it accepts no
            responsibility for any loss, expense or liability which you may
            incur from using or relying on the contents in this Website.
          </p>
          <p className="content__description">
            <span className="arrow">
              <Arrow />
            </span>
            Any legal rights and obligations which TEN ALPHAS and you may have
            will be contained in the terms and conditions of contractual
            information issued to you.
          </p>
          <h5 className="content__heading">Links within my website</h5>
          <p className="content__description">
            <span className="arrow">
              <Arrow />
            </span>
            This site may contain links to other Internet sites that belong to
            third parties. TEN ALPHAS accepts no responsibility for the accuracy
            of the content of those sites or your reliance on any information
            contained within any such site.
          </p>
          <h5 className="content__heading">Your use of this website</h5>
          <p className="content__description">
            <span className="arrow">
              <Arrow />
            </span>
            You may view, copy, print and distribute material in electronic or
            other forms only for your personal use or non-commercial purposes.
            If you do so, you must retain copyright and other intellectual
            property notices from the original material.
          </p>
          <h5 className="content__heading">You must NOT:</h5>
          <p className="content__description">
            <span className="arrow">
              <Arrow />
            </span>
            Use or distribute material from this site for any public or
            commercial purpose, or breach copyright or any other intellectual
            property rights of the presenters or its licensors including by
            amending or adapting any material on this site.
          </p>
          <h5 className="content__heading">Privacy:</h5>
          <p className="content__description">
            <span className="arrow">
              <Arrow />
            </span>
            Protecting your privacy and the confidentiality of your personal
            information is important to us, as it is fundamental to the way I
            conduct business.
          </p>
          <p className="content__description">
            <span className="arrow">
              <Arrow />
            </span>
            TEN ALPHAS is sensitive to privacy issues and treats very seriously
            the ongoing trust our customers have placed in us. TEN ALPHAS will
            not supply any personal information supplied via its website to any
            third party.
          </p>
        </main>
      </Wrapper>
    </Layout>
  );
};

export default PrivacyPage;
